<template>
    <div class="contract-detail">
        <div class="detail-box">
            <div class="left">
                <p class="title">
                    <span
                        class=" iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                        style="color:#2370EB;margin:0 14px 0 30px"
                    ></span>
                    归档
                </p>
                <el-form
                    ref="form"
                    style="flex:1;margin-top:20px;margin-left: 80px;margin-bottom:80px"
                    label-position="left"
                    :model="form"
                    label-width="100px"
                >
                    <el-form-item
                        label="关联合同"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            disabled
                            style="margin-left:10px"
                            v-model="contractTitle"
                            size="small"
                            placeholder="请输入合同标题"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="甲方"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="form.contractA"
                            size="small"
                            placeholder="请输入甲方"
                            clearable=""
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="打款人">
                        <el-input
                            v-model="form.payContract"
                            size="small"
                            placeholder="请输入打款人"
                            clearable=""
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="乙方"
                        required
                        style="margin-left:-10px"
                    >
                        <el-select
                            v-model="form.typeB"
                            size="small"
                            style="width:270px;margin-left:10px"
                            placeholder="请选择乙方"
                            clearable
                        >
                            <el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.typeB == 9">
                        <el-input
                            v-model="contractB"
                            size="small"
                            placeholder="乙方"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="合同金额"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            disabled
                            style="margin-left:10px"
                            v-model="form.contractAmount"
                            size="small"
                            placeholder="请输入合同金额"
                            clearable=""
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model="form.remark"
                            size="small"
                            placeholder="请输入备注"
                            clearable
                            style="width:270px"
                            type="textarea"
                        ></el-input>
                    </el-form-item>
                    <p>提示：提交业绩申报后将会自动将合同归档</p>
                </el-form>
            </div>
            <div class="right">
                <Relation ref="order" />
            </div>
        </div>
        <div class="bottom-but" v-if="!butDisabled">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff"
                size="mini"
                @click="onSubmit(1)"
                >稍后提交</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
                size="mini"
                @click="onSubmit(2)"
                >立即提交</el-button
            >
        </div>
        <div class="bottom-but" v-if="butDisabled">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff"
                size="mini"
                @click="onSubmit(3)"
                >保存</el-button
            >
        </div>
    </div>
</template>
<script>
import { addDeclare, editDeclare } from '@/api/user/ae/declare';
import Relation from './order.vue';
export default {
    components: {
        Relation,
    },
    data() {
        return {
            contractTitle: '',
            form: {
                contractA: '',
                payContract: '',
                typeB: '',
                contractAmount: '',
                remark: '',
            },
            contractB: '',
            data: '',
            butDisabled: false,
        };
    },
    mounted() {
        if (this.$route.query.type) {
            this.butDisabled = true;
        } else {
            this.butDisabled = false;
        }
        this.getData();
    },
    methods: {
        getData() {
            // console.log(this.$formDetails.state.data);
            let row = this.$formDetails.state.data;
            this.data = row;
            this.contractTitle = row.contractTitle;
            this.form.contractA = row.contractA;
            this.form.payContract = row.payContract;
            this.form.typeB = row.typeB;
            this.form.contractAmount = row.contractAmount;
            this.form.remark = row.remark;
            if (this.butDisabled) {
                this.form.contractAmount = row.declareAmount;
            }
            if (row.typeB == 9) {
                this.contractB = row.contractB;
            }
        },
        onClose() {
            window.history.go(-1);
        },
        onSubmit(i) {
            let orderDOList = this.$refs.order.arr;
            // console.log();
            if (orderDOList.length > 0) {
                for (let i in orderDOList) {
                    if (!orderDOList[i].productCategory) {
                        return this.$message.error('请选择产品');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2) &&
                        !orderDOList[i].version
                    ) {
                        return this.$message.error('请选择版本');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2 ||
                            orderDOList[i].productCategory == 3 ||
                            orderDOList[i].productCategory == 10) &&
                        !orderDOList[i].orderType
                    ) {
                        return this.$message.error('请选择订单类型');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2 ||
                            orderDOList[i].productCategory == 10) &&
                        !orderDOList[i].orderNumber
                    ) {
                        return this.$message.error('请输入账号数');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2 ||
                            orderDOList[i].productCategory == 10) &&
                        orderDOList[i].orderType != 3 &&
                        !orderDOList[i].orderTerm
                    ) {
                        return this.$message.error('请输入购买年限');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2 ||
                            orderDOList[i].productCategory == 10) &&
                        orderDOList[i].orderType != 3 &&
                        !orderDOList[i].supplementTerm &&
                        orderDOList[i].amount < 0
                    ) {
                        return this.$message.error('请输入增补年限');
                    } else if (
                        (orderDOList[i].productCategory == 1 ||
                            orderDOList[i].productCategory == 2) &&
                        orderDOList[i].orderType != 3 &&
                        !orderDOList[i].totalTerm
                    ) {
                        return this.$message.error('请输入下单年限');
                    } else if (
                        orderDOList[i].productCategory == 10 &&
                        orderDOList[i].orderType != 3 &&
                        !orderDOList[i].vipAmount
                    ) {
                        return this.$message.error('请输入vip账号金额');
                    } else if (
                        orderDOList[i].productCategory == 10 &&
                        orderDOList[i].orderType != 3 &&
                        !orderDOList[i].systemAmount
                    ) {
                        return this.$message.error('请输入系统金额');
                    } else if (
                        orderDOList[i].amount < 0 ||
                        !orderDOList[i].amount
                    ) {
                        return this.$message.error('请输入总金额');
                    }
                }
                if (!this.form.contractA) {
                    this.$message.error('请输入甲方');
                } else if (!this.form.typeB) {
                    return this.$message.error('请选择乙方');
                } else if (this.form.typeB == 9 && !this.contractB) {
                    return this.$message.error('请输入乙方');
                }
                var data = {
                    param: {
                        customerId: this.data.customerId,
                        contractA: this.form.contractA,
                        payContract: this.form.payContract,
                        typeB: this.form.typeB,
                        remark: this.form.remark,
                        contractOrderList: orderDOList,
                    },
                };
                if (this.form.typeB == 9) {
                    data.param.contractB = this.contractB;
                }
                if (i == 1 || i == 2) {
                    if (i == 2) {
                        data.param.status = 1;
                    }
                    data.param.declareAmount = this.data.contractAmount;
                    data.param.contractId = this.data.id;
                    addDeclare(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.onClose();
                        }
                    });
                } else if (i == 3) {
                    data.param.id = this.data.id;
                    data.param.declareAmount = this.data.declareAmount;
                    data.param.contractId = this.data.contractId;
                    editDeclare(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('修改成功');
                            this.onClose();
                        }
                    });
                }
            } else {
                this.$message.error('请添加订单');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contract-detail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-box {
        flex: 1;
        display: flex;
        height: calc(100% - 158px);
    }
    .left {
        flex: 1;
        margin-right: 16px;
        background: #fff;
        border-radius: 4px;
    }
    .right {
        background: #fff;
        flex: 1;
        border-radius: 4px;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #dee0e3;
        button {
            width: 90px;
        }
    }
    .tab-box {
        display: flex;
        align-items: center;
        height: 59px;
        border-bottom: 1px solid #eee;
        p {
            cursor: pointer;
            font-weight: 500;
            color: #666666;
            font-size: 14px;
            padding: 0 16px;
            height: 100%;
            line-height: 59px;
        }
        .isColor {
            color: #2370eb;
            position: relative;
            span {
                display: block;
                width: 16px;
                height: 3px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -8px;
            }
        }
    }
    .content-form-box {
        flex: 1;
        overflow: auto;
    }
    .el-input {
        width: 270px;
    }
    .title {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
    }
}
</style>
