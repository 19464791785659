<template>
    <div class="order">
        <p class="title">
            <span>
                <span
                    class=" iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                    style="color:#2370EB;margin:0 14px 0 30px"
                ></span>
                订单
            </span>
            <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="onAdd()"
                >添加订单</el-button
            >
        </p>
        <div class="order-box">
            <div class="order-list" v-for="(item, index) in arr">
                <div class="list-title">
                    <p class="index">订单{{ index + 1 }}</p>
                    <el-button
                        type="primary"
                        size="mini"
                        v-show="arr.length > 1"
                        @click="removeDomain(item)"
                        >删除</el-button
                    >
                </div>
                <el-form
                    style="flex:1;margin-top:20px;margin-left: 40px;margin-bottom:80px"
                    label-position="left"
                    label-width="100px"
                >
                    <el-form-item
                        label="产品名称"
                        required
                        style="margin-left:-10px"
                    >
                        <el-select
                            v-model="item.productCategory"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                            @change="Domain(item.productCategory, index)"
                        >
                            <el-option
                                label="腾讯企业邮箱"
                                value="1"
                            ></el-option>
                            <el-option
                                label="阿里企业邮箱"
                                value="2"
                            ></el-option>
                            <el-option label="云产品" value="3"></el-option>
                            <el-option
                                label="网易企业邮箱"
                                value="4"
                            ></el-option>
                            <el-option label="网站" value="5"></el-option>
                            <el-option label="投屏盒子" value="6"></el-option>
                            <el-option label="微盘" value="7"></el-option>
                            <el-option label="主机" value="8"></el-option>
                            <el-option label="服务器" value="9"></el-option>
                            <el-option label="会话存档" value="10"></el-option>
                            <el-option label="企业微信" value="11"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="域名"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2
                        "
                    >
                        <el-input
                            v-model="item.domain"
                            style="margin-left:10px"
                            size="small"
                            placeholder="请输入域名"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="版本"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2
                        "
                    >
                        <el-select
                            v-if="item.productCategory == 1"
                            v-model="item.version"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="老版" value="1"></el-option>
                            <el-option label="新版" value="2"></el-option>
                        </el-select>
                        <el-select
                            v-if="item.productCategory == 2"
                            v-model="item.version"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="标准版" value="1"></el-option>
                            <el-option label="尊享版" value="2"></el-option>
                            <el-option label="集团版" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="订单类型"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 3 ||
                                item.productCategory == 10
                        "
                    >
                        <el-select
                            v-if="
                                item.productCategory == 1 ||
                                    item.productCategory == 2 ||
                                    item.productCategory == 10
                            "
                            v-model="item.orderType"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="新增" value="1"></el-option>
                            <el-option label="续费" value="2"></el-option>
                            <el-option label="扩用户" value="3"></el-option>
                        </el-select>
                        <el-select
                            v-if="item.productCategory == 3"
                            v-model="item.orderType"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="腾讯云" value="1"></el-option>
                            <el-option label="阿里云" value="2"></el-option>
                            <el-option label="华为云" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="用户数"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 10
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.orderNumber"
                            size="small"
                            placeholder="请输入用户数"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="购买年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 10) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.orderTerm"
                            size="small"
                            placeholder="请输入购买年限"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="增补年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.supplementTerm"
                            size="small"
                            placeholder="请输入增补年限"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="下单年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.totalTerm"
                            size="small"
                            placeholder="请输入下单年限"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="vip账号金额"
                        required
                        style="margin-left:-10px"
                        v-if="item.productCategory == 10"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.vipAmount"
                            size="small"
                            placeholder="请输入vip账号金额"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="系统金额"
                        required
                        style="margin-left:-10px"
                        v-if="item.productCategory == 10"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.systemAmount"
                            size="small"
                            placeholder="请输入系统金额"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="合计金额"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.amount"
                            size="small"
                            placeholder="请输入合计金额"
                            clearable
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input
                            v-model="item.remark"
                            size="small"
                            placeholder="请输入备注"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { orderAll } from '@/api/user/business/contract.js';
import {
    orderList,
    aliReportList,
    tmReportList,
} from '@/api/user/business/contract.js';
export default {
    data() {
        return {
            field: '',
            arr: [
                {
                    domain: '',
                },
            ],
        };
    },
    mounted() {
        // console.log();
        if (this.$route.query.type) {
            this.butDisabled = true;
            this.getData();
        } else {
            this.butDisabled = false;
        }
    },
    methods: {
        // 获取订单列表
        getData() {
            let data = {
                param: {
                    contractId: this.$formDetails.state.data.contractId,
                },
            };
            orderList(data).then((res) => {
                this.arr = res.data.list;
                if (this.arr.length > 0) {
                    for (let i in this.arr) {
                        this.arr[i].productCategory = String(
                            this.arr[i].productCategory
                        );
                        this.arr[i].version = String(this.arr[i].version);
                        this.arr[i].orderType = String(this.arr[i].orderType);
                        this.arr[i].status = '1';
                    }
                }
            });
        },
        onAdd() {
            this.arr.push({});
        },
        removeDomain(item) {
            var index = this.arr.indexOf(item);
            if (index !== -1) {
                this.arr.splice(index, 1);
            }
        },
        Domain(type, i) {
            let data = {
                param: {
                    customerId: this.$formDetails.state.data.customerId,
                },
            };
            if (type == 1) {
                tmReportList(data).then((res) => {
                    // console.log(res, "TM");
                    if (res.data.list && res.data.list.length > 0) {
                        this.arr[i].domain = res.data.list[0].companyDomain;
                    }
                });
            } else if (type == 2) {
                aliReportList(data).then((res) => {
                    // console.log(res, "ALI");
                    if (
                        res.data.list.length > 0 &&
                        res.data.list[0].domainAuditStatus == 1
                    ) {
                        this.arr[i].domain = res.data.list[0].companyDomain;
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        button {
            background: none;
            border-color: #2370eb;
            color: #2370eb;
            margin-right: 16px;
        }
    }
    .order-box {
        flex: 1;
        overflow: auto;
    }
    .order-list {
        .el-input {
            width: 270px;
        }
        .list-title {
            padding: 17px 0 0 38px;
            display: flex;
            .index {
                width: 72px;
                height: 24px;
                background: #2370eb;
                border-radius: 4px 2px 4px 2px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                line-height: 24px;
                margin-right: 16px;
            }
            button {
                width: 72px;
                height: 24px;
                border-radius: 4px 2px 4px 2px;
                border: 1px solid #f5a623;
                line-height: 10px;
                background: none;
                color: #f5a623;
            }
        }
    }
}
</style>
