import http from '@/utils/http.js'

// 我的申报 
export function meDeclareList(data) {
    return http({
        url: "/jasoboss/performance/declare/list",
        method: "post",
        data
    })
}

// 部门申报
export function dempDeclareList(data) {
    return http({
        url: "/jasoboss/performance/declare/list/department",
        method: "post",
        data
    })
}
// 添加申报
export function editDeclare(data) {
    return http({
        url: "/jasoboss/performance/declare/update",
        method: "post",
        data
    })
}
// 添加申报
export function addDeclare(data) {
    return http({
        url: "/jasoboss/performance/declare/add",
        method: "post",
        data
    })
}
// 我的业绩申报 列表上方统计
export function statisticsMy(data) {
    return http({
        url: "/jasoboss/performance/declare/list/statistics",
        method: "post",
        data
    })
}
// 部门业绩申报 列表上方统计
export function statisticsDemp(data) {
    return http({
        url: "/jasoboss/performance/declare/list/statistics",
        method: "post",
        data
    })
}
// 立即申报
export function declareCommit(data) {
    return http({
        url: "/jasoboss/performance/declare/commit/declare",
        method: "post",
        data
    })
}